<template>
  <div class="relative flex h-screen lg:items-center">
    <div class="hidden h-screen w-1/2 md:block">
      <img
        src="~/assets/images/background/auth.png"
        class="h-full w-full object-cover"
        alt=""
      >
    </div>
    <div class="dark flex h-full justify-center max-lg:w-3/5 max-md:w-full md:w-2/3 lg:flex">
      <div class="w-full max-w-[22rem] px-4">
        <slot />
      </div>
    </div>
  </div>
</template>

